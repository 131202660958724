/**
 * @todo convert components/card into module and use inline js to load this module
 * @component components/cards/course
 * @description Register countdowns, ajax event, etc. for course cards.
 */

import {Countdown} from '../../modules/countdown.js';

class CountdownClock {
    /** */
    static get SINGULAR_UNITS() {
        return {
            days: 'day',
            hours: 'hr',
            minutes: 'min',
            seconds: 'sec',
        };
    }

    /**
     * @param {HTMLElement} clock
     * @param {number} endTimestamp
     * @param {Function} onCourseStarted
     */
    constructor(clock, endTimestamp, onCourseStarted = () => {}) {
        this._clock = clock;
        this._iterateElementObject();

        const endDate = new Date(endTimestamp * 1000);
        const duration = this._calcTimeDuration(endDate);
        if (isNaN(duration) || duration <= 0) return;

        // if day to start is more than 3 days, tick hourly
        // otherwise every second
        const interval = this._determineCountdownInterval(duration);

        new Countdown({
            dateEnd: endDate,
            interval: interval,
            onTick: this._onCourseTimeTicked.bind(this),
            onEnd: onCourseStarted,
        });
    }

    /**
     * @param {object} properties
     * @param {Countdown} countdownInstance
     */
    _onCourseTimeTicked(properties, countdownInstance) {
        this._update(properties);
        this._previousCountdownProps = properties;

        if (this._determineToUpdateInterval(countdownInstance.interval, properties.days)) {
            countdownInstance.interval = 1000;
        }
    }

    /** */
    _iterateElementObject() {
        this._numberElements = {
            days: this._clock.querySelector('.day .number'),
            hours: this._clock.querySelector('.hour .number'),
            minutes: this._clock.querySelector('.minute .number'),
            seconds: this._clock.querySelector('.second .number'),
        };

        this._unitElements = {
            days: this._clock.querySelector('.day .unit'),
            hours: this._clock.querySelector('.hour .unit'),
            minutes: this._clock.querySelector('.minute .unit'),
            seconds: this._clock.querySelector('.second .unit'),
        };
    }

    /**
     * @param {object} countdownProperties
     */
    _update(countdownProperties) {
        const {weeks, days} = countdownProperties;

        if (this._useDayLayout(weeks, days)) {
            const totalDays = weeks * 7 + days;

            if (!this._isDayLayout()) {
                this.#renderDaysOnlyLayout(totalDays);
            } else {
                this._updateCountdownElement('days', totalDays);
            }

            return;
        }

        if (!this._useDayLayout(weeks, days) && this._isDayLayout()) {
            this._renderFullCountdownLayout(countdownProperties);
            return;
        }

        Object.keys(countdownProperties).forEach((unit) => {
            this._updateCountdownElement(unit, countdownProperties[unit]);
        });
    }

    /**
     * @param {string} property
     * @param {number} value
     */
    _updateCountdownElement(property, value) {
        if (!this._numberElements[property]) return;
        if (this._previousCountdownProps && this._previousCountdownProps[property] === value) return;

        const unit = CountdownClock.SINGULAR_UNITS[property];

        this._numberElements[property].innerText = this._allowLeadingZeroValue(property)
            ? this._leadingZero(value)
            : value;
        this._unitElements[property].innerText = this._pluralize(unit, value);
    }

    /**
     * @param {{days: number, hours: number, minutes: number, seconds: number}} param0
     */
    _renderFullCountdownLayout({days, hours, minutes, seconds}) {
        const pluralizedDaysLabel = this._pluralize('day', days);
        const pluralizedHoursLabel = this._pluralize('hour', hours);
        const pluralizedMinutesLabel = this._pluralize('min', minutes);
        const pluralizedSecondsLabel = this._pluralize('sec', seconds);

        this._clock.innerHTML = `<div class="box clock-item day"><div class="number">${days}</div><div class="unit">${pluralizedDaysLabel}</div></div><div class="box clock-item hour"><div class="number">${this._leadingZero(
            hours
        )}</div><div class="unit">${pluralizedHoursLabel}</div></div><div class="box clock-item minute"><div class="number">${this._leadingZero(
            minutes
        )}</div><div class="unit">${pluralizedMinutesLabel}</div></div><div class="box clock-item second"><div class="number">${this._leadingZero(
            seconds
        )}</div><div class="unit">${pluralizedSecondsLabel}</div></div>`;

        this._iterateElementObject();
    }

    /**
     * @param {number} days
     */
    #renderDaysOnlyLayout(days) {
        const pluralizedDaysLabel = this._pluralize('day', days);

        this._clock.innerHTML = `<div class="box clock-item day"><div class="number">${days}</div><div class="unit">${pluralizedDaysLabel}</div></div>`;

        this._iterateElementObject();
    }

    /**
     * @param {number} weeks
     * @param {number} days
     * @returns {boolean}
     */
    _useDayLayout(weeks, days) {
        return weeks >= 1 || days >= 2;
    }

    /**
     * @returns {boolean}
     */
    _isDayLayout() {
        return this._numberElements.days && !this._numberElements.hours;
    }

    /**
     * @param {string} unit
     * @returns {boolean}
     */
    _allowLeadingZeroValue(unit) {
        return unit !== 'days';
    }

    /**
     * @param {number} duration
     * @returns {number}
     */
    _determineCountdownInterval(duration) {
        return this._calcDays(duration) > 2 ? 3600000 : 1000;
    }

    /**
     * @param {number} currentInterval
     * @param {number} days
     * @returns {boolean}
     */
    _determineToUpdateInterval(currentInterval, days) {
        return currentInterval > 1000 && days < 2;
    }

    /**
     * @param {number} duration
     * @returns {number}
     */
    _calcDays(duration) {
        const MILLISECONDS_IN_A_DAY = 86_400_000;
        return Math.floor(duration / MILLISECONDS_IN_A_DAY) + 1;
    }

    /**
     * @param {Date} endDate
     * @returns {number}
     */
    _calcTimeDuration(endDate) {
        return endDate.getTime() - Date.now();
    }

    /**
     * @param {string} word
     * @param {number} total
     * @returns {string}
     */
    _pluralize(word, total) {
        return `${word}${total === 1 ? '' : 's'}`;
    }

    /**
     * @param {number} number
     * @returns {string}
     */
    _leadingZero(number) {
        return `${number < 10 ? '0' : ''}${number}`;
    }
}

/**
 * Observers countdown timer and disables course card when the course is being started.
 * @param {HTMLElement} card
 */
function initCountdown(card) {
    /** @type {HTMLElement} */
    const countdownElement = card.querySelector('.js-countdownClock .clock');
    if (countdownElement) {
        new CountdownClock(countdownElement, Number(card.dataset.closeTimestamp), () => {
            const viewCourseButton = card.querySelector('.js-countdownDependentCardState');
            viewCourseButton.textContent = 'Closed for enrollment';
            card.classList.add('card--disabled');
        });
    }
}

/**
 * @param {HTMLElement} card
 */
function initProgressOnViewport(card) {
    /** @type {?HTMLElement} */
    const circularProgress = card.querySelector('.js-progressCircular');
    if (!circularProgress) return;

    const percentage = Number(circularProgress.dataset.percentage);
    // eslint-disable-next-line compat/compat
    const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((circularProgressObserverEntry) => {
                if (circularProgressObserverEntry.intersectionRatio >= 1) {
                    const target = /** @type {HTMLElement} */ (circularProgressObserverEntry.target);
                    updateProgress(target, percentage);
                    observer.unobserve(circularProgressObserverEntry.target);
                }
            });
        },
        {
            threshold: 1.0,
        }
    );
    observer.observe(circularProgress);
}

/**
 * @param {HTMLElement} progressRing
 * @param {number} percentage
 */
function updateProgress(progressRing, percentage) {
    const filledOutIndicator = progressRing.querySelector('.js-filledOutIndicator');
    const radius = Number(filledOutIndicator.getAttribute('r'));
    const circumference = Math.PI * (radius * 2);
    const strokeDashoffset = ((100 - percentage) / 100) * circumference;

    if (percentage === 100) {
        progressRing.classList.add('progressCircular--complete');
    }

    filledOutIndicator.setAttribute('style', `stroke-dashoffset: ${strokeDashoffset}px`);
}

/**
 * Initialize card countdown and stroke fill %
 * @param {HTMLElement} card
 */
export function initCardAnimations(card) {
    initCountdown(card);
    initProgressOnViewport(card);
}

/**
 * Initialize component, register all event handlers
 */
function initComponent() {
    const courseCards = [...document.querySelectorAll('.card[data-course-id]')];
    courseCards.forEach(
        /** @param {HTMLElement} card */
        (card) => {
            initCardAnimations(card);
        }
    );
}

// init component when document is ready
document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', initComponent) : initComponent();
